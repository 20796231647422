import { FC } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { useGetRosterQuery } from '../../api/reigniteApi';
import useEventMetadata from '../../hooks/useEventMetadata';
import { useStyles } from './styles';
import { RosterResults } from './RosterResults';
import { SearchResult } from './SearchResult';
import { usePushHistory } from './usePushHistory';
import { useSearchParams } from './useSearchParams';
import { useEventCourseIdentifiers } from './useEventCourseIdentifiers';
import { EventRosterSearch } from './EventRosterSearch';
import { TimerInfo } from '../eventResults/EventResultsComponent';


type Props = {
  races: any[]
  isMobile: boolean
  intl: any
  roster?: {
    page: number
    pageSize: number
    results: any[]
    total: number
  }
  error?: any
  isLoading: boolean
  eventCourse: any
  eventCourseId: number
  eventId: number
  masterId: number
  divisionId?: number
  divisions: any[]
  t?: (message: string, values?: Record<string, any>) => string
  timer?: any
  azp: 'ctlive'
  azpEventId: number | string
};

export const EventRosterViewComponent = ({
  races,
  isMobile,
  intl,
  roster,
  error,
  isLoading,
  eventCourse,
  eventCourseId,
  eventId,
  masterId,
  divisions,
  divisionId,
  t,
  timer,
  azp,
  azpEventId,
}: Props) => {
  const { term } = useSearchParams();
  const styles = useStyles();
  const pushHistory = usePushHistory({ masterId, eventId, eventCourseId });

  return (
    <div className={styles.pageViewWrapper}>
      <EventRosterSearch
        divisions={divisions}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        eventId={eventId}
        races={races || []}
        pushHistory={pushHistory}
      />

      {!!error && (
        <div>Error</div>
      )}

      {!roster && !error && Array.from({ length: 3 }).map((_, i) => (
        <div key={i} className={styles.loader} />
      ))}

      {roster && (
        <div className='row'>
          <div className='col-12'>
            <SearchResult
              eventCourse={eventCourse}
              intl={intl}
              isLoading={isLoading}
              roster={roster}
              term={term}
            />

            <RosterResults
              azp={azp}
              azpEventId={azpEventId}
              isLoading={isLoading}
              isMobile={isMobile}
              roster={roster}
            />

            <TimerInfo
              timer={timer}
              eventId={eventId}
              isCTLive={true} // this prop will be removed when this component is refactored
              affiliateID={+azpEventId}
              isMobile={isMobile}
              t={t}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type PureProps = {
  intl: any
  isMobile: boolean
  races: any[]
};

const PureEventRosterViewComponent = (props: PureProps) => {
  const { races } = props;
  const {
    eventCourseId,
    divisionId,
    eventId,
    masterId,
  } = useEventCourseIdentifiers(props);
  const { page, term, pageSize } = useSearchParams();

  const { data, error, isLoading } = useGetRosterQuery({
    eventCourseId,
    page,
    pageSize,
    divisionId,
    term,
  });

  const eventMetadata = useEventMetadata(eventId);
  const eventCourseMetadata = (eventMetadata.data.eventCourseMetadata || [])
    .find((item) => item.eventCourseId === eventCourseId);

  console.log({ races, roster: data, eventMetadata });
  const eventCourses = races?.find((x) => x.raceID === eventId)?.eventCourses || [];
  const eventCourse = eventCourses.find((x: any) => x.eventCourseID === eventCourseId);

  return (
    <EventRosterViewComponent
      {...props}
      azp={eventMetadata.data.azp}
      azpEventId={eventMetadata.data.azpEventId}
      error={error}
      eventCourse={eventCourse}
      eventCourseId={eventCourseId}
      eventId={eventId}
      isLoading={isLoading}
      masterId={masterId}
      divisionId={divisionId}
      roster={data}
      divisions={eventCourseMetadata?.metadata.divisions || []}
    />
  );
};

export const EventRosterView = compose(injectIntl)(
  PureEventRosterViewComponent
) as FC<Omit<Props, 'intl'>>;

