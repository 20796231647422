import { Box } from "@material-ui/core"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"

import { useGetAzpEventEntryQuery, useGetAzpEventLinkQuery } from "../../api/reigniteApi"
import useEventMetadata from "../../hooks/useEventMetadata"
import { hasRole } from '../../utils/isLoggedIn'

import { useMainStyles } from "./styles"
import { EventCard } from "./EventCard"
import { PageContainer } from "./PageContainer"
import { AthleteCard } from "./AthleteCard"
import { RaceCard } from "./RaceCard"
import { DivisionsCard } from "./DivisionsCard"
import { useMissingData } from "./hooks/useMissingData"

type AthleteRacePageProps = {
  azp: string,
  azpEntryId?: number
  azpEventId: string
  bib?: string
}

const AthleteRacePage = ({
  azp,
  bib,
  azpEntryId,
  azpEventId,
}: AthleteRacePageProps) => {
  const history = useHistory();
  const styles = useMainStyles()

  const {
    data: azpEntry,
    isLoading: isAzpEntryLoading,
  } = useGetAzpEventEntryQuery({
    azp,
    azpEntryId: azpEntryId ?? 0,
    azpEventId,
  }, {
    skip: !azp || !azpEventId || !azpEntryId,
  })

  const shouldRedirectToIRP = !!azpEntry?.hasResult || !hasRole('alpha-arp') // remove role req on full release

  const formattedEntry = useMemo(() => {
    if (!azpEntry) {
      return null
    }

    return {
      ...azpEntry,
      bib: bib ?? azpEntry.bib,
    }
  }, [azpEntry, bib])

  const { data: azpEventLinks } = useGetAzpEventLinkQuery({
    azp,
    azpEventId,
  }, {
    skip: !azp || !azpEventId,
  })

  const eventId = azpEventLinks?.athlinksEventId || 0

  const {
    data: event,
    isLoading: isLoadingEvent,
  } = useEventMetadata(eventId)

  const raceLink = azpEventLinks?.races.find(x => x.id === azpEntry?.race?.id)
  const athlinksEventCourseId = raceLink?.athlinksEventCourseId || null
  const eventCourse = event.eventCourseMetadata?.find((item) => item.eventCourseId === athlinksEventCourseId)

  const { masterId } = useMissingData({ eventId })

  const redirectingParamsExist = !!(masterId && eventId && athlinksEventCourseId && azpEntry?.bib)
  if (shouldRedirectToIRP && redirectingParamsExist) {
    history.replace(`/event/${masterId}/results/Event/${eventId}/Course/${athlinksEventCourseId}/Bib/${azpEntry.bib}`)
  }

  return (
    <PageContainer
      eventId={shouldRedirectToIRP ? null : eventId}
      eventCourseId={athlinksEventCourseId}
    >
      <Box className={styles.container}>
        <Box className={styles.leftSide}>
          <AthleteCard
            entry={formattedEntry}
            isLoadingEntry={!azpEventId || isAzpEntryLoading}
          />
        </Box>

        <Box className={styles.rightSide}>
          <EventCard
            event={event}
            isLoadingEvent={isLoadingEvent}
          />

          <RaceCard
            eventCourse={eventCourse || null}
            isLoadingEventCourse={!eventCourse}
          />

          <DivisionsCard
            azpEntry={azpEntry || null}
            eventCourse={eventCourse || null}
            eventId={eventId}
            isLoadingEntry={!azpEventId || isAzpEntryLoading}
            isLoadingEventCourse={!eventCourse}
            masterId={masterId}
          />
        </Box>
      </Box>
    </PageContainer>
  )
}

export { AthleteRacePage }