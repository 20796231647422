import { useMemo } from 'react';
import {styles, useStyles} from './styles';
import {AthletePhoto} from '../athlete/AthletePhoto';
import {Location} from '../shared/Location';
import {Link} from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { configs } from "../../configs"
import { useGetRaceAwardsQuery } from '../../api/reigniteApi'
import { hasRole } from '../../utils/isLoggedIn'

export const ProfilePreview = (props) => {
  const {
    url,
    useLink,
    Photos,
    RacerId,
    DisplayName,
    City,
    StateProvAbbrev,
    CountryId3,
    Gender,
    Age,
    Bib,
    RaceGoals,
    isMobile,
    showAthletePhoto,
    claimLink,
    nameOnly = false,
    showGender,
    eventCourseId,
    athlinksEventId,
    thirdPartyEntryId,
    isLive,
    t
  } = props;

  const hasAlphaAwardsRole = hasRole('alpha-awards')

  const { data: awardsData  } = useGetRaceAwardsQuery({
    eventCourseId: eventCourseId,
    eventId: athlinksEventId,
  },
  {
    pollingInterval: isLive
    ? 30_000
    : undefined,
    skip: !eventCourseId || !athlinksEventId || !hasAlphaAwardsRole,
  })

  
  const isWithLaurel = useMemo(() => {
    if (!awardsData || !thirdPartyEntryId || !hasAlphaAwardsRole) return false

    return awardsData.divisions
      ?.flatMap((division) => division.awards)
      ?.some((award) => award.id === thirdPartyEntryId)
  }, [awardsData, thirdPartyEntryId, hasAlphaAwardsRole])
  

  const newStyles = useStyles({ isWithLaurel });

  const hasLocation = !!(City || StateProvAbbrev || CountryId3);
  const link = url
    ? <Link to={url} className={'athName ' + (url ? 'athName-display' : '')} style={styles.profileNameLink(isMobile)}>
        {DisplayName}
      </Link>
    : <span className={'athName ' + (url ? 'athName-display' : '')}>
        {DisplayName}
      </span>

  const genderAgeInfo = useMemo(() => {
    if (Age) {
      return t('{{gender}} {{age}}', {gender: Gender, age: Age});
    }

    if (showGender) {
      return t('{{gender}}', {gender: Gender});
    }

    return null;
  }, [Age, Gender, showGender, t]);

  return (
    <div style={styles.profileCell}>
      {
        !isMobile &&
        <div style={styles.profilePhotoContainer}>
          {
            showAthletePhoto &&
            <AthletePhoto
              isMember={!!RacerId}
              photo={Photos}
              name={DisplayName}
              width={48}
            />
          }
          {
            claimLink
          }
        </div>
      }
     <div style={styles.profileNameContainer(isMobile)} className='row'>
        <div className='col- starter-row'>
          <div style={styles.profileNameLink(isMobile)}>
            {link}
          </div>
        </div>

        {!nameOnly && (
          <div className={newStyles.profileInfoContainer}>
            {isWithLaurel && (
              <img
                src={`${configs.cdnUrl}/svg/laurel_icon_small.svg`}
                alt='Laurel Wreath Left Side'
              />
            )}

            {!!genderAgeInfo && (
              <Typography className={newStyles.profileInfoText}>
                {genderAgeInfo}
              </Typography>
            )}

            {!!Bib && (
              <>
                {!!genderAgeInfo && <div className={newStyles.profileInfoDivider} />}
                <Typography className={newStyles.profileInfoText}>
                  #{Bib}
                </Typography>
              </>
            )}

            {hasLocation && <div className={newStyles.profileInfoDivider} />}

            <Location
              city={City}
              state={StateProvAbbrev}
              country={isMobile ? '' : CountryId3}
              searchType={useLink ? 'athletes' : null}
              icon={null}
              className={newStyles.profileLocation}
            />
          </div>
        )}

        {
          !nameOnly && RaceGoals &&
          <div className='col-12 pl-0' style={styles.goalsQuote}>
            "{RaceGoals.replace(/'\\/g, '')}"
          </div>
        }
      </div>
    </div>
    )
};

ProfilePreview.defaultProps = {
  useLink: true,
};
