/**
 * This hook returns some values ​​from various queries that are deprecated but currently have no alternatives.
 * The metadata for the event and the master event must be expanded to contain the selected values ​​for this hook to be removed.
 * 
 * P.S. Master event request exists, but is not yet integrated
 */

import { useGetEventCoursesQuery } from "../../../api/alaskaApi"
import { getErpEvent } from "../../../data/EventResultsStreams"
import { isRosterAvailable } from "../../../utils/rosterHelpers"

const useMissingData = ({ eventId }: { eventId: number | null }) => {
  const data = eventId ? getErpEvent({ eventId, masterId: -1 }) : null
  const currentEvent = data?._events[0].event || null

  const { data: courses } = useGetEventCoursesQuery(eventId ?? -1, {
    skip: !eventId,
  })

  if (!currentEvent) {
    return {
      isRosterTabAvailable: false,
      masterId: null,
      masterName: null,
    }
  }

  // bazuServices
  const isRosterTabAvailable = isRosterAvailable(currentEvent)

  return {
    ctLiveRegLink: courses?.CTLiveRegistrationLink,
    isRosterTabAvailable,
    learnMoreLink: currentEvent.siteUri,
    masterId: currentEvent.masterId,
    masterName: currentEvent.masterName,
    thirdPartyRegLink: currentEvent.thirdPartyRegistrationLink,
  }
}

export { useMissingData }