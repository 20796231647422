import { IRPBodyContainerComponent } from '../../components/IRP/body/IRPBodyContainer'
import { EventCourseMetadata, EventCourses } from '../../utils/types'
import { useGetIndividualResultQuery, useGetRaceAwardsQuery } from '../../api/reigniteApi'
import convertRaceTypeToTimeUnits from '../../utils/convertRaceTypeToTimeUnits'
import convertTimeUnitsToOldTimeUnit from '../../utils/convertTimeUnitsToOldTimeUnit'
import transformReigniteEntryIntervalPace from '../../utils/transformReigniteEntryIntervalPace'
import transformReigniteEntryIntervalTime from '../../utils/transformReigniteEntryIntervalTime'
import useSse from '../../lib/firebase/useSse'
import { ReigniteIndividualSseResult } from './ReigniteIndividualSseResult'
import useIrpMedia from '../../hooks/useIrpMedia'
import useFinisherCertificate from './useFinisherCertificate'
import findLastIndex from 'lodash/findLastIndex'
import { PaceUnits } from '../../types.results'
import { useMemo } from 'react'
import { IRPAward } from '../../components/IRP/body/IRPAwardSection'
import { hasRole } from '../../utils/isLoggedIn'

export function ReigniteIndividualResult(
  props: {
    athlinksEventId: number
    bib?: string
    courses: EventCourses
    entryId?: number
    event?: {
      ctliveId?: number
      timeZone: string
    }
    eventCourseId: number
    eventMetadata: any
    isLive: boolean
  }
) {
  const {
    athlinksEventId,
    bib,
    courses,
    eventCourseId,
    eventMetadata,
    entryId,
    isLive,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive
  const hasAlphaAwardsRole = hasRole('alpha-awards')

  const {
    currentData: data,
    isFetching,
    refetch,
  } = useGetIndividualResultQuery(
    {
      bib,
      entryId,
      eventId: athlinksEventId,
      eventCourseId: eventCourseId || 0,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !eventCourseId || (!entryId && !bib),
    }
  )

  const { data: awardsData  } = useGetRaceAwardsQuery({
    eventCourseId,
    eventId: athlinksEventId,
  },
  {
    pollingInterval: isLive && hasAlphaAwardsRole
      ? 30_000
      : undefined,
    skip: !eventCourseId || !athlinksEventId || !hasAlphaAwardsRole,
  })

  const thirdPartyEntryId = data?.source !== 'mssql'
  ? +(data?.id ?? 0)
  : 0

  const athleteAwards = useMemo(() => {
    if (!awardsData || !thirdPartyEntryId || !hasAlphaAwardsRole) return []

    return awardsData.divisions?.reduce<IRPAward[]>((acc, division) => {
      const award = division.awards.find(
        (award) => award.id === thirdPartyEntryId
      )
      if (!award) return acc
      return [
        ...acc,
        {
          id: award.id,
          division: division,
          gender: award.gender,
          place: award.place,
        },
      ]
    }, []) ?? []
  }, [awardsData?.divisions, thirdPartyEntryId, hasAlphaAwardsRole])


  const media = useIrpMedia({
    thirdPartyEntryId,
    thirdPartyEventId: props?.event?.ctliveId ?? 0,
  })
  const certificate = useFinisherCertificate({
    athlinksEventId,
    bib,
    entryId,
    eventCourseId,
    thirdPartyEntryId,
  })

  const eventCourseMetadata: EventCourseMetadata = eventMetadata?.data?.eventCourseMetadata?.find((x: any) => x.eventCourseId === eventCourseId)
  const intervalDivisionMetaData = eventCourseMetadata?.metadata
  const metaIntervals = intervalDivisionMetaData?.intervals ?? []
  const resultIntervals = data?.intervals

  const raceType = eventCourseMetadata?.raceType ?? 'running'

  // Remove longest course interval which should be full-course and 
  // place last in array order since it usually has a displayOrder of 1 from CTlive.
  // This allows easier discovery of mostRecentInterval with result
  const longestMetaCourseInterval = useMemo(() => {
    return metaIntervals
      ?.filter(x => x.intervalType === 'course')
      ?.sort((a, b) => b.distance - a.distance)?.[0] ?? []
  }, [metaIntervals])

  // intervals from metadata are properly ordered and contain full list
  // enrich with result intervals we have so far
  const enrichedMetaIntervals = useMemo(() => {
    if (!metaIntervals?.length) return []
    return [
      ...metaIntervals.filter(x => x !== longestMetaCourseInterval),
      longestMetaCourseInterval
    ].filter(x => !!x).map((x) => {
      const resultInterval = resultIntervals?.find((y) => y.id === x.id)

      const metaInterval = {
        distance: {
          meters: x.distance,
          units: x.distanceUnit,
        },
        chipTimeInMillis: 0,
        divisions: [],
        gunTimeInMillis: 0,
        startTimeInMillis: 0,
        raceType: resultInterval?.raceType || 'run', // rename this to something like intervalRaceType or intervalActivityType in subsequent ticket
        name: x.name,
        id: x.id,
        paceUnits: 'none' as PaceUnits,
        full: x.isFull,
      }

      if (!resultInterval) {
        return metaInterval
      }

      return {
        ...metaInterval,
        ...resultInterval,
      }
    })
  }, [metaIntervals, longestMetaCourseInterval, resultIntervals])

  const isDataEmpty = !data?.displayName || !enrichedMetaIntervals.length
  if (isDataEmpty) {
    return (
      <>
        <IRPBodyContainerComponent
          {...props}
          courses={courses}
          result={{
            fetching: isFetching,
          }}
          fetching={isFetching}
        />
      </>
    )
  }

  const {
    id,
    location,
    private: isPrivate, // 'private' is a reserved word in strict mode.
    race,
    source,
    ...pii
  } = data

  // full course interval isn't in metadata for unlinked triathalons, push from result intervals if exists
  if (raceType === 'triathlon & multisport' && !enrichedMetaIntervals.find((x) => x.full)) {
    const fullCourseIntervalResult = resultIntervals?.find((y) => y.full)
    if (!!fullCourseIntervalResult) {
      enrichedMetaIntervals.push(fullCourseIntervalResult)
    }
  }

  // find last interval with result
  const mostRecentIntervalIndex = findLastIndex(
    enrichedMetaIntervals,
    (x) => !!x.chipTimeInMillis || !!x.gunTimeInMillis
  )

  const [firstName, lastName] = pii.displayName.split(' ')

  const mapped = {
    ...pii,
    ...location,
    eventCourseId,
    eventId: athlinksEventId,
    id: source === 'mssql' ? id : 0,
    firstName,
    lastName,
    intervals: Array.from(enrichedMetaIntervals)
      .map(({
        chipTimeInMillis,
        divisions,
        gunTimeInMillis,
        startTimeInMillis,
        ...interval
      }, i) => {

        const division = divisions[0]  // TODO

        const timeUnit = convertTimeUnitsToOldTimeUnit(
          convertRaceTypeToTimeUnits(interval.raceType)
        )
        return {
          ...interval,
          brackets: divisions.map(d => ({
            ...d,
            bracketName: d.name,
            bracketRankType: d.rankType,
            bracketType: d.type,
          })),
          chipTime: {
            timeInMillis: chipTimeInMillis,
            timeUnit,
          },
          gunTime: {
            timeInMillis: gunTimeInMillis,
            timeUnit,
          },
          intervalFull: interval.name?.toUpperCase() === 'FULL COURSE', // TODO
          intervalName: interval.name,
          mostRecentInterval: i === mostRecentIntervalIndex,
          pace: !division ? 0 : transformReigniteEntryIntervalPace({
            chipTimeInMillis,
            division,
            gunTimeInMillis,
            interval,
          }),
          timeWithPenalties: !division ? 0 : transformReigniteEntryIntervalTime({
            chipTimeInMillis,
            division,
            gunTimeInMillis,
            interval,
          }),
        }
      }),
    isProfilePublic: !isPrivate,
    racerStartTime: {
      timeInMillis: data.intervals[0]?.startTimeInMillis,
      timeZone: props.event?.timeZone,
    },
    raceName: data.race.name,
    thirdPartyEntryId,
  }

  return (
    <>
      {usingSse && <ReigniteIndividualSseResult
        athlinksEventId={athlinksEventId}
        bib={bib}
        eventCourseId={eventCourseId}
        refetch={refetch}
      />}
      <IRPBodyContainerComponent
        {...props}
        awards={athleteAwards}
        certificate={certificate}
        courses={courses}
        result={mapped}
        fetching={isFetching}
        media={media}
      />
    </>
  )
}
