import { Box, Typography } from "@material-ui/core"
import moment from "moment-timezone"

import { distanceConverter } from "../../lib/anuket"
import { DistanceTypeId, EventCourseMetadata } from "../../utils/types"

import { useRaceCardStyles, useSharedStyles } from "./styles"
import { SkeletonPlaceholder } from "./SkeletonPlaceholder"

type RaceCardProps = {
  eventCourse: EventCourseMetadata | null
  isLoadingEventCourse: boolean
}

const RaceCard = ({ eventCourse, isLoadingEventCourse }: RaceCardProps) => {
  const styles = useRaceCardStyles()
  const sharedStyles = useSharedStyles()

  if (isLoadingEventCourse) {
    return <SkeletonPlaceholder />
  }
  
  if (!eventCourse) {
    return null
  }

  const mappedDistanceUnit = eventCourse.distanceUnit in DistanceTypeId
    ? DistanceTypeId[eventCourse.distanceUnit as keyof typeof DistanceTypeId]
    : DistanceTypeId.mi

  const distance = distanceConverter.distanceTo(eventCourse.distance, mappedDistanceUnit, DistanceTypeId.mi).toFixed(1)
  const startDate = moment(eventCourse.eventCourseStartDateTime.timeInMillis).tz(eventCourse.eventCourseStartDateTime.timeZone) as moment.Moment
  const daysToGo = startDate.diff(moment(), 'days')

  return (
    <Box className={`${sharedStyles.paper} ${styles.container}`}>
      <Box>
        <Typography className={`${sharedStyles.title} ${styles.title}`}>
          {eventCourse.eventCourseName}
        </Typography>

        <Typography className={styles.details}>
          {distance} Miles<span>|</span>{startDate.format('MMMM DD, YYYY h:mm A (z)')}
        </Typography>
      </Box>

      {daysToGo > 0 && (
        <Typography className={styles.daysToGo}>
          {daysToGo} Day{daysToGo > 1 && 's'} to go
        </Typography>
      )}
    </Box>
  )
}

export { RaceCard }