import { Button, Typography } from "@material-ui/core"
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGetRaceAwardsQuery } from "../../api/reigniteApi";
import { AwardsExplanation } from "../../components/award/AwardsExplanation";
import useEventMetadata from "../../hooks/useEventMetadata";
import { useMainStyles } from "./styles"
import { AwardsFilter } from "./AwardsFilter";
import { AwardsSection } from "./AwardsSection";
import { TimerInfo } from "../eventResults/EventResultsComponent";
import { EventMetadata } from '../../utils/types'
import moment from 'moment';

type EventAwardsComponentProps = {
  eventId: number
  eventName: string
  eventMetadata: EventMetadata
  events: any
  raceId: number
  divisionId?: number
  masterId: number
}

const EventAwardsComponent = ({
  eventId,
  eventName,
  eventMetadata,
  events,
  raceId,
  divisionId,
  masterId,
}: EventAwardsComponentProps) => {
  const styles = useMainStyles()

  const [expandedSections, setExpandedSections] = useState<(string | number)[]>([])

  const eventCourseMetadata = (eventMetadata.eventCourseMetadata || []).find(x => x.eventCourseId === raceId)

  const { data } = useGetRaceAwardsQuery({
    eventCourseId: raceId,
    eventId,
    divisionId
  })

  const divisionsWithAwards = useMemo(() => data?.divisions?.filter(d => !!d.depth && !!d.publish) || [], [data?.divisions])
  const allSectionIds = useMemo(() => divisionsWithAwards.map((item) => item.id), [divisionsWithAwards])

  // expand first division with awards on page load
  useEffect(() => {
    const firstDivision = divisionsWithAwards.find(
      (item) => item.awards.length > 0
    )
    if (firstDivision) {
      setExpandedSections([firstDivision.id])
    }
  }, [divisionsWithAwards])

  const showCollapseAll = divisionsWithAwards.length && divisionsWithAwards.length === expandedSections.length

  const handleExpand = useCallback((sectionId: string | number) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId) ? prev.filter((id) => id !== sectionId) : [...prev, sectionId]
    )
  }, [])

  const handleExpandCollapseAll = useCallback(() => {
    setExpandedSections(showCollapseAll ? [] : allSectionIds)
  }, [showCollapseAll, allSectionIds])

  const isEventLive = useMemo(
    () =>
      moment().isBetween(
        moment(eventMetadata?.eventStartDateTime?.timeInMillis),
        moment(eventMetadata?.eventEndDateTime?.timeInMillis)
      ),
    [eventMetadata]
  )

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <Typography className={styles.title}>Event Awards</Typography>

        <AwardsExplanation />
      </div>

      <AwardsFilter
        currentFilter={{ eventId, raceId, divisionId }}
        events={events}
        masterId={masterId}
      />

      <Typography className={styles.eventName}>{eventName}</Typography>

      <div className={styles.raceNameWrapper}>
        <Typography className={styles.raceName}>
          {eventCourseMetadata?.eventCourseName || ''}
        </Typography>

        <Button
          className={styles.expandCollapseButton}
          size='small'
          endIcon={showCollapseAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          onClick={handleExpandCollapseAll}
        >
          {showCollapseAll ? 'Collapse All' : 'Expand All'}
        </Button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', marginTop: '16px' }}>
        {eventCourseMetadata && data?.divisions?.map((item, index) => (
          <AwardsSection
            eventId={eventId}
            raceId={raceId}
            masterId={masterId}
            awards={item.awards}
            depth={item.depth}
            id={item.id}
            isExpanded={expandedSections.includes(item.id)}
            key={index}
            onToggleExpand={handleExpand}
            divisionName={item.name}
            type={item.rankType}
            eventCourseMetadata={eventCourseMetadata}
            isEventLive={isEventLive}
          />
        ))}
      </div>
    </div>
  )
}

type EventAwardsProps = Omit<EventAwardsComponentProps, 'divisions' | 'eventName'> & { timer: any, isMobile: boolean, t: any }

const EventAwards = ({ eventId, raceId, ...props }: EventAwardsProps) => {
  const eventMetadata = useEventMetadata(eventId)

  return (
    <>
    <EventAwardsComponent
      {...props}
      eventId={eventId}
      eventName={eventMetadata.data.eventName}
      raceId={raceId}
      eventMetadata={eventMetadata.data}
    />
    <TimerInfo
      timer={props.timer}
      eventId={eventId}
      isCTLive={!!eventMetadata.data.azpEventId}
      affiliateID={+eventMetadata.data.azpEventId}
      isMobile={props.isMobile}
      t={props.t}
    />
    </>
  )
}

export { EventAwards }