import { Accordion, AccordionDetails, AccordionSummary, Chip, Typography } from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { useSectionStyles } from "./styles"
import { AwardCard } from './AwardCard';
import { EntryDivisionAward } from '../../types.results.reignite'
import { EventCourseMetadata } from '../../utils/types'
import { t } from 'i18next';

type AwardsSectionProps = {
  eventId: number
  raceId: number
  eventCourseMetadata: EventCourseMetadata
  masterId: number
  awards: EntryDivisionAward[]
  depth: number
  id: string | number
  isExpanded: boolean
  divisionName: string
  type: 'gun' | 'chip'
  onToggleExpand: (id: string | number) => void
  isEventLive: boolean
}

const AwardsSection = ({
  eventId,
  raceId,
  eventCourseMetadata,
  masterId,
  awards,
  depth,
  id,
  isExpanded,
  divisionName,
  type,
  onToggleExpand,
  isEventLive,
}: AwardsSectionProps) => {

  const awardsAllFilled = awards.length === depth
  const sectionFilledStatus = awardsAllFilled ? 'full' : !!awards.length ? 'partial' : 'empty'

  const styles = useSectionStyles({ sectionFilledStatus })

  return (
    <div>
      <Accordion
        className={styles.accordion}
        expanded={isExpanded}
        onChange={() => onToggleExpand(id)}
      >
        <AccordionSummary className={styles.accordionSummary} expandIcon={<ExpandMoreIcon />}>
          <Typography className={styles.title}>{divisionName}</Typography>

          <Chip
            label={`${awards.length}/${depth}`}
            variant='outlined'
            size='small'
            className={styles.chip}
          />

          <Chip
            label={type === 'chip' ? 'Chip Time' : 'Gun Time'}
            variant='outlined'
            size='small'
            className={styles.chip}
          />
        </AccordionSummary>

        <AccordionDetails>
          {isEventLive && !!awards.length && (
            <div className={styles.warningContainer}>
              <ReportProblemOutlinedIcon />

              <Typography className={styles.warningText}>
                {t('Awards are unofficial and may change.')}
              </Typography>
            </div>
          )}

          <div className={styles.cardsWrapper}>
            {awards.map((award) => <AwardCard
              eventId={eventId}
              raceId={raceId}
              eventCourseMetadata={eventCourseMetadata}
              masterId={masterId}
              key={award.id}
              award={award}
              divisionName={divisionName}
            />)}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export { AwardsSection }