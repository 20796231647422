import { Box, Typography } from "@material-ui/core"

import { AzpEventEntry } from "../../types.results.reignite"
import { profileImageUrl } from "../../utils/profileImageUrl"

import { useAthleteCardStyles, useSharedStyles } from "./styles"
import { AthletePhoto } from "./AthletePhoto"

type AthleteCardProps = {
  entry: AzpEventEntry | null
  isLoadingEntry: boolean
}

const AthleteCard = ({
  entry,
  isLoadingEntry,
}: AthleteCardProps) => {
  const avatarSize = { mobile: 82, desktop: 120 }

  const styles = useAthleteCardStyles()
  const sharedStyles = useSharedStyles()

  if (isLoadingEntry) {
    return (
      <Box className={`${sharedStyles.paper} ${styles.container}`}>
        <AthletePhoto
          isLoading
          imageUrl={null}
          size={avatarSize}
        />

        <Box className={styles.body}>
          <Box className={styles.skeletonDisplayName} />
          <Box className={styles.skeletonDetails} />
        </Box>
      </Box>
    )
  }

  if (!entry) {
    return (
      <Box className={`${sharedStyles.paper} ${styles.container}`}>
        <Typography>
          Athlete not found
        </Typography>
      </Box>
    )
  }

  const imageUrl = (entry.racerId && profileImageUrl(entry.racerId)) || null
  const isLocationAvailable = !!(entry.location?.locality && entry.location?.country)

  return (
    <Box className={`${sharedStyles.paper} ${styles.container}`}>
      <AthletePhoto
        displayName={entry.displayName}
        imageUrl={imageUrl}
        isMember={!!entry.racerId}
        size={avatarSize}
      />

      <Box className={styles.body}>
        <Typography className={styles.displayName}>
          {entry.displayName}
        </Typography>

        <Box className={styles.detailsContainer}>
          <Typography className={styles.details}>
            {entry.gender} {entry.age}
          </Typography>

          {isLocationAvailable && (
            <Typography className={styles.details}>
              <span className={styles.detailsDivider}>|</span>
              {entry.location.locality}, {entry.location.region}
            </Typography>
          )}
        </Box>

        <Box className={styles.bibContainer}>
          <Typography className={styles.bibLabel}>
            Bib:{!!entry.bib && <span className={styles.bibNumber}>{` ${entry.bib}`}</span>}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export { AthleteCard }
