import { Box, makeStyles, useMediaQuery } from "@material-ui/core";
import moment from "moment";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { EventHero } from "../../components/heroImage/EventHero";
import useEventMetadata from "../../hooks/useEventMetadata";
import { useLearnMoreOrRegisterButton } from "../../hooks/useLearnMoreOrRegisterButton";
import { theme } from "../../shared/theme";
import { getResultsUrl } from "../../utils/resultsHelpers";
import { PageTemplate } from "../home/PageTemplate";

import { useMissingData } from "./hooks/useMissingData";

const usePageContainerStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    borderBottom: `1px solid ${palette.grey[100]}`,
    margin: '45px auto 0',
    maxWidth: '960px',
    width: '100%',

    [breakpoints.up('sm')]: {
      margin: '50px auto 0',
      paddingTop: '25px',
    },
  }
}))

type PageContainerProps = {
  children: ReactNode
  eventId: number | null
  eventCourseId: number | null
}

const PageContainer = ({ children, eventId, eventCourseId, }: PageContainerProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = usePageContainerStyles()

  const {
    isRosterTabAvailable,
    masterName,
    masterId,
    learnMoreLink,
    thirdPartyRegLink,
    ctLiveRegLink,
  } = useMissingData({ eventId })

  const {
    data: event,
    isLoading: isLoadingEventMetadata,
  } = useEventMetadata(eventId)

  const isEventLive = moment().isBetween(
    moment(event?.eventStartDateTime?.timeInMillis),
    moment(event?.eventEndDateTime?.timeInMillis)
  )

  const { learnMoreRegisterButton } = useLearnMoreOrRegisterButton({
    ctLiveRegLink,
    eventId,
    learnMoreLink,
    startTime: event?.eventStartDateTime?.timeInMillis || -1,
    thirdPartyRegLink,
  })

  const isLoadingEvent = !eventId || isLoadingEventMetadata

  return (
    <PageTemplate
      unifiedSearchMode={true}
      headerIsFixed={true}
      isMobile={isMobile}
      paddingTop={0}
      bannerTopOffset={50}
      t={t}
    >
      <Box className={styles.container}>
        <EventHero
          actionButton={learnMoreRegisterButton}
          eventId={eventId ?? -1}
          eventLogoUrl={event.logoUrl}
          eventType={event.eventType}
          isLoading={isLoadingEvent}
          isRosterTabAvailable={isRosterTabAvailable}
          isLiveRaceHeaderVisible={isEventLive}
          liveEventURL={eventId ? getResultsUrl(masterId, eventId) : ''}
          masterId={masterId}
          masterName={masterName}
          raceId={eventCourseId}
        />

        {children}
      </Box>
    </PageTemplate>
  )
}

export { PageContainer };