import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

import { InputWithFilters, SelectedFiltersItem } from "../../components/inputWithFilters/InputWithFilters"
import EventResultsBibNameSearch from "../../components/eventResultsSearch/EventResultsBibNameSearch"
import { EventResultsFilters } from "../../components/eventResultsFilters/EventResultsFilters"
import { getResultsUrl } from "../../utils/resultsHelpers"
import useEventMetadata from "../../hooks/useEventMetadata"

enum FilterKey {
  Date = 'date',
  Course = 'course',
  Division = 'division',
  Split = 'split',
}

type EventResultsSearchProps = {
  eventId?: number
  eventResultsFilters: {
    date: number
    course?: number
    division?: number
    split?: number
  }
  isCTLive: boolean
  masterId: number
  eventOptions?: {
    text: string
    value: number
  }[]
  from: number
  limit: number
  onPage: (page: number) => void
}

const EventResultsSearchComponent = ({
  eventId,
  eventResultsFilters,
  masterId,
  eventOptions,
  from,
  limit,
  isCTLive,
  onPage,
}: EventResultsSearchProps) => {
  const { t } = useTranslation()
  const history = useHistory()

  const [formValues, setFormValues] = useState(eventResultsFilters)

  const eventMetadata = useEventMetadata(formValues.date)

  const courseData = eventMetadata?.data.eventCourseMetadata;

  const selectedCourse = courseData?.find(({ eventCourseId }) => eventCourseId === eventResultsFilters?.course)
  const overallDivision = selectedCourse ? selectedCourse.metadata.divisions[0] : { id: 0, name: 'Overall' }

  const applyFilters = (values: EventResultsSearchProps['eventResultsFilters']) => {
    const { date, course, division, split } = values;
    const url = getResultsUrl(masterId, date, course, division, split);

    history.push({pathname: url});
    onPage(0);
  }

  const onClickDeleteFilter = (filterValue: string) => {
    const value = filterValue as FilterKey;

    let newValues = { ...formValues };

    if (value === FilterKey.Course) {
      newValues = { ...formValues, course: undefined, division: undefined, split: undefined };
    } else if (value === FilterKey.Division) {
      newValues = { ...formValues, division: overallDivision.id };
    } else if (value === FilterKey.Split) {
      newValues = { ...formValues, split: undefined };
    }

    applyFilters(newValues);
  }

  const selectedFilters = useMemo<SelectedFiltersItem[]>(() => {
    const filters: SelectedFiltersItem[] = []

    if (!courseData?.length || !eventOptions?.length) {
      return filters;
    }

    const selectedDate = eventOptions.find((option) =>
      option.value === eventResultsFilters.date);

    if (selectedDate) {
      filters.push({ label: selectedDate.text, value: FilterKey.Date });
    }

    if (!selectedCourse) {
      filters.push({ label: t('All Race Types'), value: FilterKey.Course })

      return filters;
    }

    filters.push({
      label: selectedCourse.eventCourseName,
      value: FilterKey.Course,
      isRemovable: true,
    });

    const selectedDivision = eventResultsFilters.division
      ? selectedCourse.metadata.divisions.find(division => division.id === eventResultsFilters.division)
      : overallDivision

    if (selectedDivision) {
      filters.push({
        label: selectedDivision.name,
        value: FilterKey.Division,
        isRemovable: selectedDivision.id !== overallDivision.id
      });
    }
    
    const selectedSplit = eventResultsFilters.split ?
      selectedCourse.metadata.intervals.find((split: { id: number }) =>
        split.id === eventResultsFilters.split)
      : isCTLive ? { name: 'All Splits' } : null;

    if (selectedSplit) {
      filters.push({
        label: selectedSplit.name,
        value: FilterKey.Split,
        isRemovable: selectedSplit.name !== 'All Splits',
      });
    }

    return filters
  }, [courseData, eventOptions, eventResultsFilters.course, eventResultsFilters.date, eventResultsFilters.division, eventResultsFilters.split, t])

  return (
    <InputWithFilters
      selectedFilters={selectedFilters}
      onClickApply={() => applyFilters(formValues)}
      onClickDeleteFilter={onClickDeleteFilter}
      onOpenFilters={() => setFormValues(eventResultsFilters)}
      renderInput={() => (
        <EventResultsBibNameSearch
          isMobile={false}
          masterEventId={masterId}
          eventId={eventId || eventResultsFilters.date}
          eventMetadata={eventMetadata}
        />
      )}
      renderFilters={() => (
        <EventResultsFilters
          // @ts-ignore
          masterId={masterId}
          masterData={eventOptions}
          courseData={courseData}
          from={from}
          limit={limit}
          onPage={onPage}
          isCTLive={isCTLive}
          eventResultsFilters={formValues}
          onChange={setFormValues}
        />
      )}
    />
  )
}

const mapStateToProps = (state: any) => ({
  eventResultsFilters: state.eventResultsFilters
});

export const EventResultsSearch = connect(mapStateToProps)(EventResultsSearchComponent)