import { Box, Link, Typography } from "@material-ui/core"
import { useMemo } from "react"
import { Link as ReactRouterLink } from 'react-router-dom'

import { AzpEventEntry } from "../../types.results.reignite"
import { DivisionMetadata, EventCourseMetadata } from "../../utils/types"

import { useDivisionsCardStyles, useSharedStyles } from "./styles"
import { SkeletonPlaceholder } from "./SkeletonPlaceholder"

type DivisionItemProps = DivisionMetadata & {
  athletesCount: number
  rosterLink: string
}

const DivisionItem = ({
  athletesCount,
  awards,
  id,
  name,
  rosterLink,
}: DivisionItemProps) => {
  const styles = useDivisionsCardStyles()

  return (
    <Box className={styles.divisionItem}>
      <Box className={styles.divisionName}>
        <Typography>{name}</Typography>
      </Box>

      <Box>
        <Typography className={styles.divisionSectionTitle}>
          Athletes
        </Typography>

        <Typography className={styles.divisionAthletesCount}>
          {athletesCount}
        </Typography>
      </Box>

      {!!awards && (
        <Box className={styles.divisionAwardsContainer}>
          <Typography className={styles.divisionSectionTitle}>
            Top {awards.depth} Awards
          </Typography>
      
          <Typography className={styles.divisionTimeType}>
            By {awards.rankType === 'chip' ? 'Chip' : 'Gun'} Time
          </Typography>
        </Box>
      )}

      <Box className={styles.divisionLink}>
        <Link
          to={`${rosterLink}/Division/${id}`}
          component={ReactRouterLink}
        >
          View Roster {'>'}
        </Link>
      </Box>
    </Box>
  )
}

type DivisionsCardProps = {
  azpEntry: AzpEventEntry | null
  eventCourse: EventCourseMetadata | null
  eventId: number
  isLoadingEntry: boolean
  isLoadingEventCourse: boolean
  masterId: number
}

const DivisionsCard = ({
  azpEntry,
  eventCourse,
  eventId,
  isLoadingEntry,
  isLoadingEventCourse,
  masterId,
}: DivisionsCardProps) => {
  const styles = useDivisionsCardStyles()
  const sharedStyles = useSharedStyles()

  const mappedDivisions = useMemo(() => {
    if (!azpEntry || !eventCourse) {
      return []
    }

    const entryDivisionIds = eventCourse?.metadata.divisions.reduce((acc: Omit<DivisionItemProps, 'rosterLink'>[], division) => {
      const azpEntryDivision = azpEntry.divisions.find((item) => item.id === division.id)

      if (azpEntryDivision) {
        acc.push({ ...division, athletesCount: azpEntryDivision.count })
      }

      return acc
    }, [])

    return entryDivisionIds
  }, [azpEntry, eventCourse])

  if (isLoadingEntry || isLoadingEventCourse) {
    return <SkeletonPlaceholder />
  }

  if (!eventCourse) {
    return null
  }

  return (
    <Box className={sharedStyles.paper}>
      <Typography className={sharedStyles.title}>Divisions</Typography>

      <Box className={styles.itemsContainer}>
        {mappedDivisions.map((division) => (
          <DivisionItem
            key={division.id}
            {...division}
            rosterLink={`/event/${masterId}/roster/Event/${eventId}/Course/${eventCourse.courseId}`}
          />
        ))}
      </Box>
    </Box>
  )
}

export { DivisionsCard }