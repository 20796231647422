import { Chip, Paper, Typography, useMediaQuery } from "@material-ui/core"
import { theme } from "../../shared/theme"
import { AthletePhoto } from "../../components/athlete/AthletePhoto"
import { AwardWreathWithCaption } from "../../components/award/AwardLaurelWithCaption"
import { EntryDivisionAward } from "../../types.results.reignite"
import { profileUserImageUrl } from "../../utils/profileImageUrl"
import { Link } from 'react-router-dom'
import { getResultsUrlForBib } from '../../utils/resultsHelpers'
import { useCardStyles, usePerformanceItemStyles } from "./styles"
import { convertTime, UnitType } from '@eventops/athlinks-lib-units'
import { TimeUnit } from '@eventops/athlinks-results-model'
import { EventCourseMetadata } from '../../utils/types'
import { useSelector } from 'react-redux'
import calculatePace from '../../utils/calculatePace'

const Skeleton = ({ className }: { className: string }) => {
  const styles = useCardStyles({ isPending: true })

  return (
    <div className={`${styles.skeleton} ${className}`} />
  )
}

type PerformanceItemProps = {
  className?: string
  label: string
  showLabel?: boolean
  value?: string | null
  chip?: {
    label: string
    color: string
  }
}

const PerformanceItem = ({
  className,
  label,
  showLabel = true,
  value,
  chip
}: PerformanceItemProps) => {
  const styles = usePerformanceItemStyles({ chipBackground: chip?.color })

  return (
    <div className={`${className} ${styles.wrapper}`}>
      <div className={styles.container}>
        {showLabel && (
          <Typography className={styles.label}>
            {label}
          </Typography>
        )}

        {typeof value !== 'undefined' && !value && (
          <Skeleton className={styles.skeletonValue} />
        )}

        {!!value && (
          <Typography className={styles.value}>
            {value}
          </Typography>
        )}

        {chip && (
          <Chip
            label={chip.label}
            size='small'
            className={styles.chip}
          />
        )}
      </div>
    </div>
  )
}

type AwardCardProps = {
  eventId: number
  raceId: number
  eventCourseMetadata: EventCourseMetadata
  masterId: number
  award: EntryDivisionAward
  divisionName: string
}

const AwardCard = ({ eventId, raceId, masterId, award, eventCourseMetadata, divisionName }: AwardCardProps) => {
  const unitType = useSelector((state: { userPrefs: { globalUnitType: UnitType } }) => state.userPrefs.globalUnitType)

  const profPicUrl = profileUserImageUrl(award.racerId, 't', !!award.racerId, true);

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const styles = useCardStyles({})
  const userLink = getResultsUrlForBib(eventId, raceId, award.bib, masterId)

  const calculatedPace = calculatePace({
    timeInMillis: award.timeInMillis,
    interval: eventCourseMetadata.metadata.intervals.find(x => x.isFull),
    unitType
  })

  const showWreathCaption = divisionName.length < 10

  return (
    <Link to={userLink} style={{ textDecoration: 'none' }}>
      <Paper className={styles.container}>
        <div>
          <AwardWreathWithCaption
            caption={showWreathCaption ? divisionName : ''}
            place={award.place}
          />

          <Typography className={styles.label}>
            {!showWreathCaption ? divisionName : ''}
          </Typography>
        </div>
        <div>
          <div className={styles.athleteNameWrapper}>
            <>
              <div className={styles.athleteAvatar}>
                <AthletePhoto
                  isMember={!!award.racerId}
                  size='Thumb'
                  photo={profPicUrl}
                  name={award.displayName}
                />
              </div>

              <Typography className={styles.athleteName}>
                {award.displayName}
              </Typography>
            </>
          </div>
          <>
            <Typography className={styles.athleteInfo}>
              {award.gender} {award.age}<span>|</span>#{award.bib}
            </Typography>
            <Typography className={styles.athleteInfo}>
              {award.location?.locality}, {award.location?.region}
            </Typography>
          </>
        </div>

        <div className={styles.performanceSection}>
          {!isMobile && (
            <PerformanceItem
              label={`PACE ${calculatedPace.label}`}
              value={calculatedPace.value}
            />
          )}

          <PerformanceItem
            label='TIME'
            showLabel={!isMobile}
            value={convertTime({ timeInMillis: award.timeInMillis, timeUnit: TimeUnit.HOURS }).value}
          />
        </div>
      </Paper>
    </Link>
  )
}

export { AwardCard }