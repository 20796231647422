import { Theme, makeStyles } from '@material-ui/core';

import { colors, fonts, loadingDark } from '../../shared/styles';

type StyleProps = {
  isImageLoaded?: boolean;
  isLogoLoaded?: boolean;
  withCustomHeroImage?: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>(({ palette, breakpoints }) => ({
  detailsWrapper: {
    boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '0 0 15px 15px',
    maxWidth: '960px',
    padding: '8px 12px',
    position: 'relative',
    width: '100%',

    [breakpoints.up('sm')]: {
      padding: '8px 24px',
    }
  },
  detailsContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    minHeight: '48px',
  },
  detailsLeftSide: {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
  },
  detailsSkeleton: {
    height: '24px',
    margin: '12px 0',
    width: '60%',
    ...loadingDark,
  },
  eventDetailsDate: {
    fontSize: '14px',
    color: colors.grey,
  },
  logo: ({ isLogoLoaded }) => ({
    height: '48px',
    objectFit: 'contain',
    display: isLogoLoaded ? 'block' : 'none',
    width: '48px',
  }),
  name: {
    color: palette.grey[800],
    fontSize: '19px',
    lineHeight: '19px',
    margin: 0,
    ...fonts.heavy,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',

    [breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '24px',
    }
  },
  mainTextStyle: {
    fontSize: 16,
    color: palette.common.white,
    fontFamily: 'ProximaNovaRegular'
  },
  tabMenuContainer: {
    alignItems: 'center',
    borderBottom: `1px solid ${palette.grey[100]}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px',

    [breakpoints.up('sm')]: {
      padding: '12px 24px',
    }
  },
  tabMenuTab: {
    padding: '9px 16px',
    color: palette.grey[800],
    fontSize: '14px',
    display: 'inline-block',
    lineHeight: '24px',
    borderBottom: '2px solid transparent',
    textTransform: 'uppercase',
    ...fonts.bold,
  },
  tabMenuActiveTab: {
    borderBottom: `2px solid ${palette.brandColor}`,
    color: palette.brandColor,
  },
  tabMenuSkeletonContainer: {
    display: 'flex',
    width: '100%',
    gap: '12px',
  },
  tabMenuSkeleton: {
    borderBottom: '2px solid transparent',
    height: '24px',
    margin: '9px 0',
    width: '90px',
    ...loadingDark,
  },
  stickyContainer: {
    backgroundColor: palette.common.white,
    position: 'sticky',
    top: '45px',
    zIndex: 1000,

    [breakpoints.up('sm')]: {
      top: '50px',
    }
  },
  heroImage: ({ isImageLoaded, withCustomHeroImage }) => ({
    display: isImageLoaded ? 'block' : 'none',
    maxHeight: withCustomHeroImage ? 'auto' : '110px',
    objectFit: 'cover',
    width: '100%',

    [breakpoints.up('sm')]: {
      borderRadius: '10px 10px 0 0',
      maxHeight: withCustomHeroImage ? 'auto' : '270px',
    }
  }),
  heroImageSkeleton: {
    width: '100%',
    height: '110px',
    ...loadingDark,
    
    [breakpoints.up('sm')]: {
      borderRadius: '10px 10px 0 0',
      height: '270px',
    }
  },
  location: {
    fontSize: '12px',
    paddingLeft: 0,
    paddingRight: 0
  },
  register: {
    minWidth:120,
    marginBottom: 20,
    textTransform: 'uppercase',
    height: 42,
    fontFamily: 'ProximaNovaRegular',
    color: palette.common.white,
    fontSize: 18,
    borderRadius: 2,
    backgroundColor: palette.brandColor
  },
  loadMore: {
    textAlign: 'center',
    width: '100%',
    background: palette.brandColor,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 0,
    marginTop: 20
  },
  startDate: {
    paddingRight: 10,
    marginRight: 10,
    float: 'left',
    borderRight: `1px solid ${colors.lightGrey}`
  },
}));
