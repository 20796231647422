import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {stateMap} from '../../utils/stateMap';
import {isLoggedIn, getTokenRacerId} from '../../utils/isLoggedIn';
import {profileImageUrl} from '../../utils/profileImageUrl';
import {ProfilePreview} from '../profile/ProfilePreview';
import Style from 'style-it';
import {VRBadgeLink} from '../shared/VRBadge';
import {ClaimResultButton} from '../control/ClaimResult';
import {
  showClaimLink,
  verifyGender,
  shouldShowRankings,
} from '../../utils/ERPUtil';
import { showPace } from '../../utils/IRPUtil';
import {styles} from './styles';
import {getResultsUrlForBib} from '../../utils/resultsHelpers';
import {RowArrow} from '../shared/RowArrow';
import {AthlinksClaimedFlame} from '../shared/AthlinksClaimedFlame';
import { convertPace, convertTime } from '@eventops/athlinks-lib-units';
import _ from 'lodash';
import { getDisplayLocationString } from '../../resultsClient/getDisplayLocationString';
import { connect } from 'react-redux'
import convertPaceDistanceUnit from '../../utils/convertPaceDistanceUnit'

class EventResultRowComponent extends Component {
  static propTypes = {
    hasResultInEventCourse: PropTypes.bool.isRequired,
    claimCallback: PropTypes.func,
    eventCourseId: PropTypes.number,
    tokenRacerId: PropTypes.number,
    t: PropTypes.func
  };

  static defaultProps = {
    claimCallback: () => {},
  };

  state = {
    hover: false,
    tokenRacerId: getTokenRacerId(),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps) || (this.state.hover !== nextState.hover);
  }

  getRankingColumn(isMobile, rank) {
    if(!isMobile) {
      return (
        <div className="col px-0" style={styles.dataColumn}>
          {
            shouldShowRankings(this.props.entryStatus, this.props.intervalFull)
              ? rank > 0 ? rank : ''
              : '--'
          }
        </div>
      );
    }

    return null;
  }

  getPace(pace, unitType) {
    const shouldShowPace = showPace(pace) && !['DQ', 'DNF'].includes(this.props.entryStatus)
    if (shouldShowPace) {
      const calculatedPace = convertPace(convertPaceDistanceUnit(pace, unitType))
      return (
        <div>
          <div>{calculatedPace.value.toString()}</div>
          <div style={{fontSize: 10, letterSpacing: .5, fontFamily: 'ProximaNovaBold', color: '#9b9b9b', marginTop: -3}}>
            {calculatedPace.label.toUpperCase()}
          </div>
        </div>
      )
    }

    return <div>{'--'}</div>
  }

  render() {
    const {
      masterId,
      isMobile,
      racerId,
      entryId,
      eventId,
      eventCourseId,
      thirdPartyEntryId,
      entryStatus,
      entryDate,
      entryType,
      eventName,
      eventCity,
      eventState,
      isVirtual,
      eventCountry,
      shouldShowPace,
      showGender,
      bib,
      displayName,
      age,
      gender,
      locality,
      region,
      country,
      primaryBracketRank,
      genderRank,
      overallRank,
      time,
      isFriend,
      selectedEventStatus,
      hasResultInEventCourse,
      claimCallback,
      t,
      unitType,
      pace
    } = this.props;
      const {
        tokenRacerId,
      } = this.state

    const formattedTime = convertTime({timeInMillis: time.timeInMillis, timeUnit: 'h'}).value; // time
    const claimed = !!racerId;

    const claimButtonProps = {
      age,
      gender,
      fullName: displayName,
      athleteLocation: getDisplayLocationString(locality, region, country),
      entryId: entryId || 0,
      ctEntryId: thirdPartyEntryId || 0,
      bib,
      eventCourseId,
      finalTime: formattedTime,
      date: entryDate,
      eventName,
      isLoggedIn: isLoggedIn(),
      isMobile,
      isClaimed: claimed,
      racerId,
      isLive: true,
      resultOnERP: true,
      ctLiveEventId: selectedEventStatus?.ctLiveEventId,
      onClaimCallback: claimCallback,
      city: eventCity,
      state: eventState,
      country: eventCountry,
      t
    };

    const irpUrlBib = getResultsUrlForBib(eventId, eventCourseId, bib, masterId, entryId);
    const profPicUrl = profileImageUrl(racerId, 't', claimed);
    const showEntryStatus = (entryStatus === 'DNF' && this.props.intervalFull) || entryStatus === 'DQ';

    const stateAbbrev = stateMap[region] || region;

    const claimLink = showClaimLink(claimed, hasResultInEventCourse, entryId, thirdPartyEntryId, entryType)
      ?
      <ClaimResultButton {...claimButtonProps}/>
      :
      <div style={styles.noAvatar} />;

    const clickHandler = (e) => {
      if (!e.isDefaultPrevented() && e.currentTarget.contains(e.target)) {
        e.preventDefault();
        this.props.history.push({pathname: irpUrlBib});
      }
    }

    let actionOrTimeLabel = formattedTime
    if (isVirtual) {
      if (claimed && isLoggedIn() && racerId === tokenRacerId && formattedTime === '0:00') {
        actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>{this.props.t('Post Time')}</span>
      }
      else if (claimed && isLoggedIn() && racerId !== tokenRacerId && formattedTime === '0:00') {
        actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>--</span>
      }
      else if (!claimed && formattedTime === '0:00') {
        actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>--</span>
      }
      else if (!isLoggedIn() && formattedTime === '0:00') {
        actionOrTimeLabel = <span style={{fontFamily: 'LatoBold', fontSize: 13}}>{this.props.t('Post Time')}</span>
      }
    }

    return (
      Style.it(styles.pseudoStyles(isMobile),
        <div className='row mx-0 link-to-irp' style={styles.tableRow()} onClick={clickHandler}>
          <div className='col-5 p-0'>
            <ProfilePreview
              url={irpUrlBib}
              useLink={false}
              RacerId={racerId}
              Photos={profPicUrl}
              DisplayName={displayName}
              City={locality}
              StateProvAbbrev={stateAbbrev}
              Gender={verifyGender(age, gender, t)}
              Age={age}
              Bib={bib}
              isClaimed={claimed}
              isFriend={isFriend}
              CountryId3={country}
              isMobile={isMobile}
              profPicUrl={profPicUrl}
              showAthletePhoto={claimed}
              showGender={showGender}
              claimLink={claimLink}
              eventCourseId={eventCourseId}
              athlinksEventId={eventId}
              thirdPartyEntryId={thirdPartyEntryId}
              t={t}
            />
          </div>
          {
            this.getRankingColumn(isMobile, overallRank)
          }
          {
            this.getRankingColumn(isMobile, genderRank)
          }
          {
            this.getRankingColumn(isMobile, primaryBracketRank)
          }
          {
            !isMobile && shouldShowPace ?
              <div className="col px-0" style={styles.dataColumn}>
                {
                  this.getPace(pace, unitType)
                }
              </div>
            : <div className="col px-0" style={styles.dataColumn} />
          }
          {
            !isMobile &&
            <div className="col-2 px-0"
                 style={styles.timeColumn}>
              <div>
                {
                  claimed &&
                  <AthlinksClaimedFlame style={styles.claimFlame.desktop} className='claimed-flame'/>
                }
                {
                  showEntryStatus
                    ? entryStatus
                    : actionOrTimeLabel
                }
                <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}> {
                  isVirtual === true ? <VRBadgeLink props={this.props}/> : null
                }</div>
              </div>
            </div>
          }
          <div className='col- resultArrow' style={styles.rowArrowColumn.desktop}>
            <RowArrow />
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  unitType: state.userPrefs.globalUnitType,
})

export const EventResultRow = connect(mapStateToProps)(withTranslation()(withRouter(EventResultRowComponent)));
