import { useMediaQuery } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { theme } from '../../shared/theme';
import { SocialShare } from '../shared/SocialShare';
import { getResultsUrl } from '../../utils/resultsHelpers';
import { hasRole } from '../../utils/isLoggedIn';

import { useStyles } from './styles';

interface EventHeaderTabMenuProps {
  eventId: number;
  isLoading?: boolean;
  isRosterTabAvailable: boolean;
  masterId: number;
  raceId: number | null;
}

const EventHeaderTabMenu = ({
  eventId,
  isLoading,
  isRosterTabAvailable,
  masterId,
  raceId,
}: EventHeaderTabMenuProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({});

  const tabs = useMemo(() => {
    const items = [{
      key: 'about',
      label: t('about'),
      url: `/event/${masterId}`,
    }]

    if (isRosterTabAvailable) {
      items.push({
        key: 'roster',
        label: t('roster'),
        url: `/event/${masterId}/roster`,
      })
    }

    items.push({
      key: 'results',
      label: t('results'),
      url: getResultsUrl(masterId, eventId),
    })

    if (raceId && hasRole('alpha-awards')) {
      items.push(
        {
          key: 'awards',
          label: t('awards'),
          url: `/event/${masterId}/awards/Event/${eventId}/Course/${raceId}`,
        })
    }

    return items;
  }, [eventId, isRosterTabAvailable, masterId, raceId, t]);

  const isActive = useCallback((location, tab) => {
    if (tab.key === 'about' && /^\/event\/\d+$/.test(location.pathname)) {
      return true
    }

    if (tab.key === 'roster' && /^\/event\/\d+\/roster/.test(location.pathname)) {
      return true
    }

    if (tab.key === 'roster' && /^\/azp\/ctlive\/event/.test(location.pathname)) {
      return true
    }

    if (tab.key === 'awards' && /^\/event\/\d+\/awards/.test(location.pathname)) {
      return true
    }

    if (tab.key === 'awards' && /^\/azp\/ctlive\/event/.test(location.pathname)) {
      return true
    }

    if (tab.key === 'results' && /^\/event\/\d+\/results/.test(location.pathname)) {
      return true
    }

    return false
  }, []);

  if (isLoading) {
    return (
      <div className={styles.tabMenuContainer}>
        <div className={styles.tabMenuSkeletonContainer}>
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={index} className={styles.tabMenuSkeleton} />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.tabMenuContainer}>
      <div>
        {tabs.map((tab) => (
          <NavLink
            isActive={(_, location) => isActive(location, tab)}
            key={tab.key}
            to={tab.url}
            activeClassName={styles.tabMenuActiveTab}
            className={styles.tabMenuTab}
          >
            {tab.label}
          </NavLink>
        ))}
      </div>

      <SocialShare
        followButton={false}
        isMobile={isMobile}
        buttonsProps={{ isMobile }}
      />
    </div>
  );
};

export { EventHeaderTabMenu };

export type { EventHeaderTabMenuProps }
