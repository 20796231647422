import { makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core"

import { fonts } from "../../../shared/styles"
import { theme } from "../../../shared/theme"
import { AwardsExplanation } from "../../award/AwardsExplanation"
import { AwardWreathWithCaption } from "../../award/AwardLaurelWithCaption"
import { hasRole } from '../../../utils/isLoggedIn'
import { DivisionAwards, EntryDivisionAward } from "../../../types.results.reignite"

import { styles as rootStyles } from "./styles"
import { useHistory } from 'react-router-dom'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { t } from 'i18next'
import useEventMetadata from '../../../hooks/useEventMetadata'
import moment from 'moment'
import { useMemo } from 'react'

export type IRPAward = Pick<EntryDivisionAward, 'id' | 'gender' | 'place'> & {
  division: Pick<DivisionAwards, 'id' | 'name'>
}

type IRPAwardSectionProps = {
  awards: IRPAward[]
  eventCourseId: number
  eventId: number
  masterId: number
}

const useStyles = makeStyles<Theme, { isFullWidthAward: boolean }>(({ breakpoints }) => ({
  awardLink: {
		flex: 1,
    cursor: 'pointer',
	},
  awardContainer: ({ isFullWidthAward }) => ({
		alignItems: 'center',
		background: 'rgba(22, 169, 225, 0.10)',
		border: '1px solid #0288D1',
		borderRadius: '4px',
		display: 'flex',
		padding: '12px',

    [breakpoints.up('sm')]: isFullWidthAward ? {} : {
      flexDirection: 'column',
    }
	}),
	awardLabel: ({ isFullWidthAward }) => ({
    '& p': {
      color: '#014361',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      marginLeft: '12px',
      
      [breakpoints.up('sm')]: isFullWidthAward ? {} : {
        marginLeft: 0,
        textAlign: 'center',
      }
    },

    [breakpoints.up('sm')]: {
      margin: '8px 0 0',
    },
	}),
  awardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  container: {
    display: 'flex',
		flexDirection: 'column',
		gap: '8px',
  },
  title: {
    color: '#424242',
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: 0.4,
    ...fonts.bold,
  },
  titleContainer: {
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
		  justifyContent: 'space-between',
    }
  },
  warningContainer: {
    background: '#FFF4E5',
    borderRadius: '4px',
    display: 'flex',
    gap: '12px',
    marginBottom: '12px',
    padding: '6px 16px',

    '& svg': {
      color: '#ED6C02',
      marginTop: '8px',
    }
  },
  warningText: {
    color: '#663C00',
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '0.15px',
    lineHeight: '24px',
    margin: '8px 0',
  },
}))

const IRPAwardSection = ({
  awards,
  eventCourseId,
  eventId,
  masterId
}: IRPAwardSectionProps) => {
  const history = useHistory()
  
  const isFullWidthAward = awards.length === 1

  const styles = useStyles({ isFullWidthAward })
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const { data: eventMetadata } = useEventMetadata(eventId)

  const isEventLive = useMemo(
    () =>
      moment().isBetween(
        moment(eventMetadata?.eventStartDateTime?.timeInMillis),
        moment(eventMetadata?.eventEndDateTime?.timeInMillis)
      ),
    [eventMetadata]
  )

  const renderAwardWarning = () => {
    return (
      <div className={styles.warningContainer}>
        <ReportProblemOutlinedIcon />

        <Typography className={styles.warningText}>
          {t('Awards are unofficial and may change.')}
        </Typography>
      </div>
    )
  }

  if (!hasRole('alpha-awards') || !awards.length) return null

  return (
    <div className={styles.container} style={rootStyles.cardContainer}>
      {awards.length > 1 && (
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>Awards</Typography>
          <AwardsExplanation />
        </div>
      )}

      <div>
        {isEventLive && renderAwardWarning()}

        <div className={styles.awardsWrapper}>
          {awards.map((award) => (
            <div
              key={award.id}
              className={styles.awardLink}
              onClick={() =>
                history.push(
                  `/event/${masterId}/awards/Event/${eventId}/Course/${eventCourseId}/Division/${award.division.id}`
                )
              }
            >
              <div className={styles.awardContainer}>
                <AwardWreathWithCaption
                  caption={award.gender}
                  place={award.place}
                  size='mobile'
                />

                <div className={styles.awardLabel}>
                  {(isMobile || isFullWidthAward) && (
                    <Typography>
                      Award Winner{awards.length > 1 && ' |'}{' '}
                      {award.division.name}
                    </Typography>
                  )}

                  {!isMobile && !isFullWidthAward && (
                    <>
                      <Typography>Award Winner</Typography>

                      <Typography>{award.division.name}</Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {awards.length === 1 && <AwardsExplanation />}
    </div>
  )
}

export { IRPAwardSection }